<template>
    <div class="container flex flex-col">
        <div class="pt-3 pb-8">
            <h1 class="text-black text-xl lg:text-2xl mb-3">Factsheets</h1>
        </div>
        <div class="pb-8 w-1/6">
            <Search v-model="search" placeholder="Search all factsheets" />
        </div>
        <ResourceList :resources="filteredIndicatorResources" :endpoint="'indicator-resource'" />
    </div>
</template>

<script>
import Search from '@/components/partials/Search'
import ResourceList from '@/components/resource-list/ResourceList'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import camelcaseKeys from 'camelcase-keys'
import GET_INDICATOR_RESOURCES from '@/graphql/queries/getIndicatorResources.gql'
import GET_SURVEY_VERSIONS_FOR_USER from '@/graphql/queries/getSurveyVersionsForUser.gql'
import RoleMixin from '@/components/shared/mixins/roleHelpers'

export default {
    name: 'FactsheetsView',
    components: { Search, ResourceList },
    mixins: [ AuthMixin, RoleMixin ],
    data() {
        return {
            search: ''
        }
    },
    apollo: {
        indicatorResources: {
            query: GET_INDICATOR_RESOURCES,
            debounce: 200,
            variables() {
                return {
                    search: `%${this.search}%`
                }
            },
            skip() {
                return !this.canViewResources
            },
            update({ indicatorResources }) {
                // TODO: need to add code to skip this stuff if user doesn't have
                this.loading = false
                return camelcaseKeys(indicatorResources, {deep: true})
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
        surveyVersions: {
            query: GET_SURVEY_VERSIONS_FOR_USER,
            variables() {
                return {
                    userId: this.userId
                }
            },
            skip() {
                // we want to run this if skipFiltered is false
                return !this.userId || !this.hasPracticeManagerRole
            },
            update({ surveyVersions }) {
                // console.log('survey versions', surveyVersions)
                return camelcaseKeys(surveyVersions || {}, { deep: true })
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
    },
    computed: {
        surveyVersionIds() {
            if (!this.surveyVersions || !this.surveyVersions.length) {
                return null
            }

            return this.surveyVersions.reduce((arr, curr) => {
                if (curr.accreditations && curr.accreditations.length) {
                    const id = curr.accreditations[0].surveyVersion.id
                    if (id && !arr.includes(id)) {
                        arr.push(id)
                    }
                }
                return arr
            }, [])
        },
        filteredIndicatorResources() {
            if (!this.indicatorResources || !this.indicatorResources.length) {
                return []
            }

            return this.indicatorResources.filter(resource => {
                if (this.isAdmin) return true

                if (this.isSurveyor && resource.forSurveyor) {
                    return true
                }
                if (this.hasPracticeManagerRole && resource.forPm) {
                    return this.surveyVersionIds && resource.indicatorResourceIndicators.some(resourceIndicator => {
                        return this.surveyVersionIds.includes(resourceIndicator.indicator.surveyVersion.id)
                    })
                }

                return false
            })
        }
    },
    watch: {
        rolesLoaded(loaded) {
            if (loaded && !this.canViewResources) {
                this.$router.push('/Dashboard')
            }
        }
    }
}
</script>
